<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab :active="!$route.query.tab || $route.query.tab === ''">

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('account.titles.data.title') }}</span>
      </template>

      <b-card
        :header="$t('account.titles.data.title')"
        header-tag="h4"
      >
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('form.first_name.label')"
                label-for="account-first-name"
              >
                <b-form-input
                  v-model="form.first_name"
                  :placeholder="$t('form.first_name.label')"
                  name="first_name"
                  :state="errors && errors.first_name ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.first_name">
                  {{ errors.first_name[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('form.last_name.label')"
                label-for="account-last-name"
              >
                <b-form-input
                  v-model="form.last_name"
                  name="last_name"
                  :placeholder="$t('form.last_name.label')"
                  :state="errors && errors.last_name ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.last_name">
                  {{ errors.last_name[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('form.phone.label')"
                label-for="account-company"
              >
                <b-form-input
                  v-model="form.phone"
                  name="phone"
                  :placeholder="$t('form.phone.label')"
                  :state="errors && errors.phone ? false : null"
                  disabled
                />
                <b-form-invalid-feedback v-if="errors && errors.phone">
                  {{ errors.phone[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              v-if="loaded"
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="submit"
              >
                {{ $t('general.save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-tab>
    <!--/ general tab -->

    <!-- notify tab -->
    <b-tab :active="$route.query.tab && $route.query.tab === 'notifications'">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('account.titles.notify.title') }}</span>
      </template>

      <b-card
        :header="$t('account.titles.notify.title')"
        header-tag="h4"
      >
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.notify_general.label')"
                label-for="notify_general"
              >
                <b-form-checkbox
                  id="notify_general"
                  v-model="form.notify_general"
                  value="1"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('form.notify_promo.label')"
                label-for="notify_promo"
              >
                <b-form-checkbox
                  id="notify_promo"
                  v-model="form.notify_promo"
                  value="1"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-card
                style="border:1px solid #eeeeee;"
              >
                <b-row>
                  <b-col
                    cols="12"
                    :md="hasRealEmail ? '8' : '12'"
                  >
                    <b-form-group
                      :label="$t('form.email.label')"
                      label-for="account-e-mail"
                    >
                      <b-form-input
                        v-model="form.email"
                        name="email"
                        :placeholder="$t('form.email.label')"
                        :state="errors && errors.email ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.email">
                        {{ errors.email[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="hasRealEmail"
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.test.label')"
                      label-for="test-e-mail"
                    >
                      <b-button
                        variant="warning"
                        block
                        @click="testNotify('email')"
                      >
                        {{ $t('general.testemail') }}
                      </b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col cols="12">
              <b-card
                style="border:1px solid #eeeeee;"
              >
                <b-row>
                  <b-col
                    cols="12"
                    :md="hasRealTg ? '8' : '12'"
                  >
                    <b-form-group
                      :label="$t('form.telegram.label')"
                      label-for="account-e-mail"
                    >
                      <b-form-input
                        v-model="form.telegram"
                        name="telegram"
                        :placeholder="$t('form.telegram.label')"
                        :state="errors && errors.telegram ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.telegram">
                        {{ errors.telegram[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="hasRealTg"
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.test.label')"
                      label-for="test-telegram"
                    >
                      <b-button
                        variant="warning"
                        block
                        @click="testNotify('telegram')"
                      >
                        {{ $t('general.testtelegram') }}
                      </b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div v-html="$t('info.bot.start')" />
              </b-card>
            </b-col>
            <b-col
              v-if="loaded"
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="submit"
              >
                {{ $t('general.save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>

      <Notifications />
    </b-tab>
    <!--/ notify tab -->

    <!-- finance tab -->
    <b-tab :active="$route.query.tab && $route.query.tab === 'finance'">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="CreditCardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('account.titles.finance.title') }}</span>
      </template>

      <b-card
        :header="$t('account.titles.finance.title')"
        header-tag="h4"
      >
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <h2>
                {{ $options.filters.moneyFormat(form.balance) }}
              </h2>
            </b-col>
          </b-row>
        </b-form>
      </b-card>

      <Transactions
        ref="refTransactions"
      />
    </b-tab>
    <!--/ finance tab -->

    <!-- referral tab -->
    <b-tab :active="$route.query.tab && $route.query.tab === 'referral'">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('account.titles.referral.title') }}</span>
      </template>

      <b-card
        :header="$t('account.text.referral.title')"
        header-tag="h4"
      >
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <div class="mb-2">
              <h5>Розповідайте про сервіс та заробляйте гроші</h5>
              <h5>Ласкаво просимо до панелі управління реферальної програми TeLeAds.com.ua . Діліться своїм унікальним посиланням, щоб запрошувати нових користувачів.</h5>
              <h5>
                Заробляйте
                <b-badge variant="danger">
                  {{ prc.cr }}%
                </b-badge>
                з кожною успішною інтеграцією замовника.
              </h5>
              <h5 v-if="prc.grer && prc.grer > 0">
                +діє вступний бонус
                <b-badge variant="danger">
                  {{ prc.grer }}грн
                </b-badge>
                - запросіть друга і отримайте {{ prc.grer }}грн
              </h5>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-1"
          >
            <b-img
              fluid
              :src="imgUrl"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="refLinkPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="refLinkPageOptions"
              :reduce="val => val.value"
              :clearable="false"
              class="w-100"
            />
          </b-col>
          <b-col
            cols="12"
            md="7"
          >
            <b-form-group class="mb-0">
              <b-form-input
                v-model="refLink"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-button
              variant="primary"
              block
              @click="doCopy"
            >
              <feather-icon
                icon="ClipboardIcon"
                size="14"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-card
            no-body
            class="card-apply-job"
          >
            <b-card-header class="pb-1">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="80"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="30"
                      icon="SendIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h4 class="transaction-title">
                    {{ $t('account.text.referral.telegram.title') }}
                  </h4>
                  <small class="text-muted">
                    {{ $t('account.text.referral.telegram.description') }}
                  </small>
                </b-media-body>
              </b-media>
            </b-card-header>
            <b-card-body>
              <b-button
                block
                variant="primary"
                @click="doCopy"
              >
                <feather-icon
                  icon="ClipboardIcon"
                  size="14"
                  class="mr-50"
                />
                {{ $t('account.text.referral.telegram.btn') }}
              </b-button>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-card
            no-body
            class="card-apply-job"
          >
            <b-card-header class="pb-1">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="80"
                    variant="light-info"
                  >
                    <feather-icon
                      size="30"
                      icon="FacebookIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h4 class="transaction-title">
                    {{ $t('account.text.referral.social.title') }}
                  </h4>
                  <small class="text-muted">
                    {{ $t('account.text.referral.social.description') }}
                  </small>
                </b-media-body>
              </b-media>
            </b-card-header>
            <b-card-body>
              <div class="soc_buttons">
                <b-button
                  variant="info"
                  @click="toShare('tg')"
                >
                  <feather-icon
                    icon="SendIcon"
                    size="14"
                    class="mr-50"
                  />
                  Telegram
                </b-button>
                <b-button
                  variant="primary"
                  @click="toShare('fb')"
                >
                  <feather-icon
                    icon="FacebookIcon"
                    size="14"
                    class="mr-50"
                  />
                  Facebook
                </b-button>
                <b-button
                  variant="success"
                  @click="toShare('wt')"
                >
                  <feather-icon
                    icon="MessageCircleIcon"
                    size="14"
                    class="mr-50"
                  />
                  Whatsapp
                </b-button>
                <b-button
                  variant="secondary"
                  @click="toShare('email')"
                >
                  <feather-icon
                    icon="MailIcon"
                    size="14"
                    class="mr-50"
                  />
                  E-mail
                </b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <Referrals />
    </b-tab>
    <!--/ referral tab -->
  </b-tabs>
</template>

<script>
/* eslint-disable global-require */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import GlobalMixin from '@mixins/GlobalMixin'
import useJwt from '@/auth/jwt/useJwt'
import Referrals from '@/views/account/setting/referrals/List.vue'
import Transactions from '@/views/account/setting/transactions/List.vue'
import store from '@/store'
import Notifications from '@/views/account/setting/notifications/List.vue'

export default {
  components: {
    Notifications,
    Transactions,
    Referrals,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      loaded: false,
      hasRealEmail: false,
      hasRealTg: false,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        telegram: null,
        notify_general: null,
        notify_promo: null,
      },
      pass: {
        password: null,
        confirmation: null,
      },
      prc: {
        afmn: 0,
        afmx: 0,
        awmn: 0,
        awmx: 0,
        cf: 0,
        cfm: 0,
        cr: 0,
        cs: 0,
        cw: 0,
        cwm: 0,
        gral: 0,
        grer: 0,
      },
      downImg: require('@/assets/images/pages/error.svg'),
      refLinkPage: '',
      refLinkPageOptions: [
        { label: this.$t('account.ref_pages.home'), value: '' },
        { label: this.$t('account.ref_pages.catalog'), value: ':c' },
        { label: this.$t('account.ref_pages.hot'), value: ':h' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    refLink() {
      return `${process.env.VUE_APP_APP_URL}/?r=${this.form.token}${this.refLinkPage}`
    },
    refTitle() {
      return 'Реферальна програма'
    },
    refDescription() {
      return this.$i18n.t('account.text.referral.description').replace(':comm', this.form.transaction_settings.cr)
    },
  },
  beforeCreate() {
    this.$http.get('/api/profile').then(res => {
      const {
        first_name, last_name, email, phone, telegram, notify_general, notify_promo, balance, transaction_settings, token,
      } = res.data.data
      this.form = {
        first_name, last_name, email, phone, telegram, notify_general, notify_promo, balance, transaction_settings, token,
      }
      this.loaded = true
      this.hasRealTg = !!telegram
      this.hasRealEmail = !!email
    })
  },
  mounted() {
    this.prc = JSON.parse(localStorage.getItem('prc')) ?? {}
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    refetchData() {
      this.$http.get('/api/profile').then(res => {
        const {
          first_name, last_name, email, phone, telegram, notify_general, notify_promo,
        } = res.data.data
        this.form = {
          first_name, last_name, email, phone, telegram, notify_general, notify_promo,
        }
        this.loaded = true
        this.hasRealTg = !!telegram
        this.hasRealEmail = !!email
      })
    },
    submit() {
      const {
        first_name, last_name, email, phone, telegram, notify_general, notify_promo,
      } = this.form

      const data = {
        first_name, last_name, email, phone, telegram, notify_general, notify_promo,
      }

      this.$http.put('/api/profile', data)
        .then(response => {
          localStorage.setItem(useJwt.jwtConfig.userDataKey, JSON.stringify(response.data.data))
          this.refetchData()

          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    testNotify(type = 'email') {
      const data = {
        type,
        description: `Тест "${type}" - ${new Date().toLocaleDateString()}`,
      }
      this.$http.post('/api/profile/test_notify', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.ooops'),
              text: this.$t('notifications.unprocessable_request'),
              icon: 'CoffeeIcon',
              variant: 'danger',
            },
          })
        })
    },
    submitPass() {
      const {
        password, confirmation,
      } = this.pass

      const data = {
        password, confirmation,
      }

      this.$http.put('/api/profile/password', data)
        .then(() => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    doCopy() {
      this.$copyText(this.refLink).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'BellIcon',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
    toShare(soc) {
      let link = ''
      if (soc === 'fb') {
        link = `https://www.facebook.com/share.php?u=${encodeURI(this.refLink)}&title=${encodeURI(this.refTitle)}`
      }
      if (soc === 'tg') {
        link = `https://t.me/share/url?url=${encodeURI(this.refLink)}&text=${encodeURI(this.refTitle)}`
      }
      if (soc === 'email') {
        link = `mailto:?subject=${encodeURI(this.refTitle)}&body=${encodeURI(this.refTitle)}%20${encodeURI(this.refLink)}`
      }
      if (soc === 'wt') {
        link = `https://web.whatsapp.com/send?text=${encodeURI(this.refTitle)}%20${encodeURI(this.refLink)}`
      }
      window.open(link, 'TeLeAds', 'width=600,height=600')
    },
  },
}
</script>

<style type="text/scss" scoped>
.soc_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:10px;
}
</style>
