<template>
  <b-sidebar
    id="opened-transaction-form"
    :visible="openedTransactionForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-transaction-form', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('account.titles.finance.details') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card class="card-transaction">
        <!-- BODY -->
        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-primary"
              >
                <feather-icon
                  size="18"
                  icon="CalendarIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('account.titles.transaction.date') }}
              </h6>
              <small>
                {{ $t('account.titles.transaction.transaction') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-right">
            {{ item.created_at }}
          </div>
        </div>

        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-success"
              >
                <feather-icon
                  size="18"
                  icon="AnchorIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('account.titles.transaction.description') }}
              </h6>
              <small>
                {{ $t('account.titles.transaction.transaction') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-right">
            {{ item.description }}
          </div>
        </div>

        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-danger"
              >
                <feather-icon
                  size="18"
                  icon="ClipboardIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('account.titles.transaction.service') }}
              </h6>
              <small>
                {{ $t('account.titles.transaction.transaction') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-right">
            {{ $t(`account.transaction.services.${item.service}`) }}
            <br> {{ item.method }}
          </div>
        </div>

        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-warning"
              >
                <feather-icon
                  size="18"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('account.titles.transaction.amount') }}
              </h6>
              <small>
                {{ $t('account.titles.transaction.transaction') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-right">
            {{ item.type + $options.filters.moneyFormat(item.amount) }}
          </div>
        </div>

        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                variant="light-info"
              >
                <feather-icon
                  size="18"
                  icon="DivideIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('account.titles.transaction.commission') }}
              </h6>
              <small>
                {{ $t('account.titles.transaction.services') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-right">
            {{ $options.filters.moneyFormat(item.commission) }}
          </div>
        </div>

        <div class="transaction-item">
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
              >
                <feather-icon
                  size="18"
                  icon="AwardIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ $t('account.titles.transaction.status') }}
              </h6>
              <small>
                {{ $t('account.titles.transaction.transaction') }}
              </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder">
            <b-badge
              v-if="item.status === 'pending'"
              variant="warning"
            >
              {{ $t(`general.statuses.${item.status}`) }}
            </b-badge>
            <b-badge
              v-if="item.status === 'approved'"
              variant="success"
            >
              {{ $t(`general.statuses.${item.status}`) }}
            </b-badge>
            <b-badge
              v-if="item.status === 'rejected'"
              variant="danger"
            >
              {{ $t(`general.statuses.${item.status}`) }}
            </b-badge>
          </div>
        </div>

        <b-img
          v-if="item.avatar"
          rounded
          :src="$options.filters.mediaUrl(item, 'avatar', 'base')"
          style="width: 100%;"
        />
      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BCard, BMedia, BMediaBody, BMediaAside, BAvatar, BBadge, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
    BCard,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BBadge,
    BImg,
  },
  model: {
    prop: 'openedTransactionForm',
    event: 'update:opened-transaction-form',
  },
  props: {
    openedTransactionForm: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-transaction-form', false)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-transaction-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
