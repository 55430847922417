<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-1"
        >
          <label for="per_page">{{ $t('market.table.settings.show') }}</label>
          <v-select
            id="per_page"
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="w-100"
          />
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refListTable"
      class="position-relative"
      :items="fetchData"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('market.table.settings.empty_text')"
      :sort-desc.sync="isSortDirDesc"
    >
      <template #cell(token)="data">
        <feather-icon
          v-b-tooltip.hover.top
          icon="Share2Icon"
          size="16"
          :title="data.item.token"
          variant="outline-primary"
        />
      </template>

      <template #cell(amount)="data">
        <b-badge :variant="data.item.type === '+' ? 'success' : 'danger'">
          {{ data.item.type + $options.filters.moneyFormat(data.item.amount) }}
        </b-badge>
      </template>

      <template #cell(commission)="data">
        {{ $options.filters.moneyFormat(data.item.commission) }}
      </template>

      <template #cell(status)="data">
        <b-badge
          v-if="data.item.status === 'pending'"
          variant="warning"
        >
          {{ $t(`general.statuses.${data.item.status}`) }}
        </b-badge>
        <b-badge
          v-if="data.item.status === 'approved'"
          variant="success"
        >
          {{ $t(`general.statuses.${data.item.status}`) }}
        </b-badge>
        <b-badge
          v-if="data.item.status === 'rejected'"
          variant="danger"
        >
          {{ $t(`general.statuses.${data.item.status}`) }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <span
          class="btn btn-primary btn-sm"
          @click="openedTransactionForm = true; transaction = data.item"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="align-middle"
          />
        </span>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('market.table.settings.showing') }} {{ dataMeta.from }} {{ $t('market.table.settings.to') }} {{ dataMeta.to }} {{ $t('market.table.settings.of') }} {{ dataMeta.of }} {{ $t('market.table.settings.entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <transaction-form
      :opened-transaction-form.sync="openedTransactionForm"
      :item="transaction | full_freeze"
      @refetch-data="refetchData"
    />
  </b-card>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import TransactionForm from '@/views/account/setting/transactions/TransactionForm'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useList from './useList'
import storeModule from './storeModule'

export default {
  components: {
    TransactionForm,
  },
  mixins: [GlobalMixin],
  props: {
    type: {
      type: String,
      default: 'transactions',
    },
  },
  data() {
    return {
      openedTransactionForm: false,
      transaction: {},
      tableColumns: [
        { key: 'token', label: this.$t('market.table.fields.id3'), sortable: false },
        { key: 'amount', label: this.$t('market.table.fields.amount'), sortable: false },
        { key: 'commission', label: this.$t('market.table.fields.commission'), sortable: false },
        { key: 'status', label: this.$t('market.table.fields.status'), sortable: false },
        { key: 'created_at', label: this.$t('market.table.fields.date'), sortable: true },
        { key: 'actions', label: this.$t('market.table.fields.actions'), sortable: false },
      ],
    }
  },
  created() {
    this.trType = this.type
  },
  methods: {
    //
  },
  setup() {
    const STORE_MODULE_NAME = 'transactions'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      trType,
    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      trType,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
